<template>
  <div class="newsLaunch">
    <Head :type=0></Head>
    <Carousel></Carousel>
    <div class="newsLaunch_con">
        <div class="launch_top">
            <p class="top_left">新闻动态</p>
            <p class="top_right">
                <span>当前位置:首页</span>
                <span> | </span>
                <span>最新资讯</span>
            </p>
        </div>
        <div class="launch_con">
            <h3 class="h3">{{newDetail.title}}</h3>
            <div v-html="newDetail.content"></div>
        </div>
    </div>
    <RightSide></RightSide>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import Carousel from "@/components/Carousel.vue";
import RightSide from "@/components/RightSide.vue";
import { getNewsDetail } from "@/api/common";

export default {
  name: "NewsLaunch",
  components: {
    Head,
    Footer,
    Carousel,
    RightSide
  },
  data() {
    return {
      id: '',
      newDetail: {}
    }
  },
  methods: {
    getNewsDetail() {
      getNewsDetail(this.id).then((res) => {
        if (res.data.code == 200) {
          this.newDetail = res.data.data
        } else {

        }
      });
    },
  },
  mounted() {
    this.id = this.$route.query.index
    this.getNewsDetail()
  },
};
</script>
<style scoped lang="less">
.newsLaunch {
  .newsLaunch_con {
      width: 1280px;
      margin: 0 auto;
      box-sizing: border-box;
      margin-bottom: 146px;
  }
  .launch_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 88px;
    .top_left {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
    }
    .top_right {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
    }
  }
  .launch_con {
    .h3 {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        line-height: 37px;
        text-align: center;
        margin-bottom: 16px;
    }
    .word {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        margin-top: 57px;
        text-indent:40px
    }
    .img_com {
        margin-top: 40px;
        width: 100%;
        height: 699px;
        background: #DCDCDC;
    }
    .img_three {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }
    .img_two {
        width: 750px;
        height: 860px;
    }
    .img_one {
        width: 471px;
        height: 860px;
        background: #DCDCDC;
    }
  }
}
</style>
